import React, { Component } from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import Recaptcha from "react-recaptcha";
import { DateTime } from "luxon";

import {
  subdomain,
  API_URL,
  API_CONFIG,
  API_MULTIPART_CONFIG,
} from "../constants";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Divider from "../ui/Divider";
import Button from "../ui/Button";
import Label from "../ui/Label";
import Input from "../ui/Input";
import InputRadios from "../ui/InputRadios";
import Textarea from "../ui/Textarea";
import InputError from "../ui/InputError";

// const ValidationSchema = [];

const medicalQuestions = [
  {
    question:
      "Are there any reasons (medical or otherwise) why you would not be unable to carry out strenuous physical work including climbing ladders, working from scaffolding, bending, lifting and carrying?",
  },
  { question: "Do you have any eye or eyesight problems?" },
  { question: "Do you have any hearing problems or difficulties?" },
  {
    question:
      "Do you take any tablets or medication? – If so please answer at bottom of page.",
  },
  {
    question: "Have you ever had any of the following?",
    questions: [
      { question: "Diabetes" },
      { question: "Tuberculosis" },
      { question: "Angina" },
      { question: "Any other heart trouble" },
      { question: "Raised blood pressure" },
      { question: "Peptic, gastric or duodenal ulcer" },
      { question: "Indigestion for more than one week" },
      { question: "Back trouble, lumbago, sciatica, “slipped disc”?" },
      { question: "Epilepsy, recurring blackout or fits" },
    ],
  },
  {
    question:
      "Have you ever had any of the following during the past five years?",
    questions: [
      { question: "Bronchitis, asthma, pneumonia?" },
      { question: "Dermatitis, eczema or other skin trouble?" },
    ],
  },
  {
    question: "Do you suffer from any of the following?",
    questions: [
      { question: "Migraine or severe recurring headaches" },
      { question: "Anxiety, depression or any other nervous complaint" },
      { question: "Fainting attacks or giddiness" },
      { question: "Ear trouble, discharging or infected ear" },
      { question: "Kidney trouble or urinary infection" },
    ],
  },
  {
    question:
      "Have you ever had any operations requiring hospital admission for more than five days?",
  },
  {
    question:
      "Have you ever been off work continuously for more than a month during the last 5 years?",
  },
  {
    question:
      "Have you ever had to give up any previous job for medical reasons?",
  },
  { question: "Do you have any other disability or serious illness?" },
  { question: "Have you ever received compensation or a disability pension?" },
  {
    question:
      "Have you consulted your doctor in the past 12 months regarding your health?",
  },
  { question: "Do you classify yourself as being Disabled?" },
];

const medicalKeys = {};
const medicalValidation = {
  addtional_medicial_information: Yup.string().trim().required("Required"),
};
medicalQuestions.forEach((question, i) => {
  if (question.questions) {
    question.questions.forEach((subQuestion, k) => {
      medicalKeys["medical_" + i + "_" + k] = "";
      medicalValidation["medical_" + i + "_" + k] = Yup.string()
        .required("Required")
        .oneOf(["yes", "no"]);
    });
  } else {
    medicalKeys["medical_" + i] = "";
    medicalValidation["medical_" + i] = Yup.string()
      .required("Required")
      .oneOf(["yes", "no"]);
  }
});

// const ValidationSchema = [];

const ValidationSchema = [
  Yup.object().shape({
    watched_video: Yup.bool().oneOf(
      [true],
      "The Induction Video must be watched to the end."
    ),
  }),
  Yup.object().shape({
    region: Yup.string().trim().required("Required"),
    first_name: Yup.string().trim().required("Required"),
    middle_name: Yup.string().trim().required("Required"),
    last_name: Yup.string().trim().required("Required"),
    dob: Yup.string().trim().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    address: Yup.string().trim().required("Required"),
    postcode: Yup.string().trim().required("Required"),
    //home_phone: Yup.string().trim().required("Required"),
    mobile_phone: Yup.string().trim().required("Required"),
    hiring_supervisor: Yup.string().trim().required("Required"),
    ethnic_group: Yup.string().trim().required("Required"),
    ethnic_other: Yup.string().trim().required("Required"),
    // ethnic_other: Yup.string().when("ethnic_group", {
    //   is: "Other",
    //   then: Yup.string().trim().required("Required"),
    // }),
    nationality: Yup.string().trim().required("Required"),
    right_to_work: Yup.string().trim().required("Required"),
    right_to_work_uploads: Yup.array(),
    right_to_work_comments: Yup.string().trim().required("Required"),
    marital_status: Yup.string().trim().required("Required"),
    password: Yup.string().trim().required("Required").min(6, "Too short."),
    password_confirmation: Yup.string()
      .trim()
      .required("Required")
      .oneOf([Yup.ref("password")], "Password does not match"),
  }),
  Yup.object().shape({
    cisrs_number: Yup.string().trim().required("Required"),
    ni_number: Yup.string().trim().required("Required"),
    utr_number: Yup.string().trim().required("Required"),
    card_held: Yup.string().trim().required("Required"),
    card_expiry: Yup.string().trim().required("Required"),
    experience: Yup.string().trim().required("Required"),
    employment_status: Yup.string().trim().required("Required"),
    has_worked_for_db: Yup.string()
      .trim()
      .required("Required")
      .oneOf(["yes", "no"]),
  }),
  Yup.object().shape({
    accounts_name: Yup.string().trim().required("Required"),
    account_number: Yup.string().trim().required("Required"),
    sort_code: Yup.string().trim().required("Required"),
    bank_name: Yup.string().trim().required("Required"),
    bank_reference: Yup.string().trim().required("Required"),
    bank_address: Yup.string().trim().required("Required"),
  }),
  Yup.object().shape(medicalValidation),
  Yup.object().shape({
    contact_name_1: Yup.string().trim().required("Required"),
    contact_relationship_1: Yup.string().trim().required("Required"),
    contact_phone_1: Yup.string().trim().required("Required"),
    contact_name_2: Yup.string().trim().required("Required"),
    contact_relationship_2: Yup.string().trim().required("Required"),
    contact_phone_2: Yup.string().trim().required("Required"),
  }),
  Yup.object().shape({
    uploads: Yup.array()
      .required("Please attach supporting documents")
      .min(1, "Please attach supporting documents"),
  }),
  Yup.object().shape({
    declaration: Yup.bool().oneOf([true], "Required"),
    acknowledgement: Yup.bool().oneOf([true], "Required"),
    confirm_standards: Yup.bool().oneOf([true], "Required"),
  }),
  Yup.object().shape({
    confirm_contract: Yup.bool().oneOf([true], "Required"),
    recaptcha: Yup.string().trim().required("Required"),
  }),
];

// const PickCompany = () => {
//   return (
//     <div className="flex flex-col h-screen w-full items-center justify-center">
//       <p>Please confirm which region you’re induction is for</p>
// 
//       <div className="mt-4 flex flex-col md:flex-row items-center justify-center">
//         <NavLink
//           to="/head-office"
//           className="border rounded-lg border-gray-200 p-8 lg:p-12 mx-4"
//         >
//           <img src="/logo-colour.png" width="150" alt="" />
//         </NavLink>
//         <NavLink
//           to="/northern"
//           className="border rounded-lg border-gray-200 p-8 lg:p-12 mx-4"
//         >
//           <img src="/logo-northern-colour.png" width="150" alt="" />
//         </NavLink>
//       </div>
//     </div>
//   );
// };


class Dashboard extends Component {
  state = {
    subdomain: (subdomain === "enroll" || subdomain === "induction") ? "head-office" : "northern",
    currentStep: 0,
    fields: false,
    captchaKey: false,
    showPassword: false,
    steps: [
      "Review Induction video",
      "Personal Information",
      "Work Information",
      "Banking Information",
      "Medical Information",
      "Emergency Contact Information",
      "File Attachments",
      "Declaration & Acknowledgement",
      "Self-Employed Contract for Services",
    ],
    isLoading: true,
    isUploading: false,
    right_to_work_files: [],
    files: [],
    file: null,
    validatingEmail: false,
    emailError: false,

    submitted: {
      error: false,
      status: "pending",
    },
  };

  componentDidMount() {
    // get recapthca code...
    axios
      .get(API_URL + "/induction/get-data", API_CONFIG)
      .then((response) => {
        this.setState(
          {
            fields: response.data.fields,
            captchaKey: response.data.captcha_sitekey,
            isLoading: false,
          },
          () => {
            if (!document.getElementById("recaptcha")) {
              const script = document.createElement("script");
              script.src = "https://www.google.com/recaptcha/api.js";
              script.async = true;
              script.defer = true;
              script.id = "recaptcha";
              document.body.appendChild(script);
            }
          }
        );
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  sendInduction = (values) => {
    this.setState(
      {
        submitted: {
          error: false,
          status: "submitting",
        },
      },
      () => {
        axios
          .post(API_URL + "/submit-induction", values, API_CONFIG)
          .then((response) => {
            this.setState({
              submitted: {
                error: false,
                status: "complete",
              },
            });
          })
          .catch((error) => {
            this.setState({
              submitted: {
                error: error.message,
                status: "error",
              },
            });
          });
      }
    );
  };

  setEvidenceFiles = (acceptedFiles, setFieldValue) => {
    this.setFiles(acceptedFiles, setFieldValue, 'right_to_work_')
  }

  setFiles = (acceptedFiles, setFieldValue, field = '') => {
      const files = [];
    const acceptable = [".jpg", ".jpeg", ".gif", ".png"];

    Array.from(acceptedFiles).forEach((file) => {
      console.log(this.state.files);
      // read the file then push it to the state...
      if (file.size > 20 * 100 * 100 * 100) {
        files.push({
          error: "File is too big",
          status: "error",
          file: file,
        });
        return;
      }

      let mimeType = file.type;
      let baseMimeType = mimeType ? mimeType.replace(/\/.*$/, "") : "";
      let valid = false;

      acceptable.forEach((validType) => {
        validType = validType.trim();
        if (validType.charAt(0) === ".") {
          if (
            file.name
              .toLowerCase()
              .indexOf(
                validType.toLowerCase(),
                file.name.length - validType.length
              ) !== -1
          ) {
            valid = true;
            return false;
          }
        } else if (/\/\*$/.test(validType)) {
          // This is something like a image/* mime type
          if (baseMimeType === validType.replace(/\/.*$/, "")) {
            valid = true;
            return false;
          }
        } else {
          if (mimeType === validType) {
            valid = true;
            return false;
          }
        }
      });

      if (!valid) {
        files.push({
          error: "Invalid file type",
          status: "error",
          file: file,
        });
      } else {
        files.push({
          error: false,
          status: "pending",
          file: file,
        });
      }
    });

    this.setState(
      {
        [field+'files']: [...this.state[field+'files'], ...files],
      },
      () => {
        this.beginUpload(setFieldValue, field);
      }
    );
  };

  removeFile = (index, setFieldValue, field = '') => {
    let newArray = this.state[field+'files'].slice();
    newArray.splice(index, 1);
    this.setState(
      {
        files: newArray,
      },
      () => {
        setFieldValue(
          "uploads",
          this.state[field+'files']
            .filter((o) => o.status === "complete")
            .map((o) => o.filename)
        );
      }
    );
  };

  beginUpload = (setFieldValue, field = '') => {
    if (this.state.isUploading) {
      return;
    }
    // what software do we need to load...
    const nextFile = this.state[field+'files'].find((o) => o.status === "pending");
    console.log("beginUpload", nextFile);
    this.uploadFile(nextFile, setFieldValue, field);
  };

  uploadFile = (file, setFieldValue, field = '') => {
    if (!file) {
      this.setState(
        {
          status: "complete",
          isUploading: false,
          [field+'files']: this.state[field+'files'].map((o) => {
            if (o.status === "importing") {
              o.status = "complete";
            }
            return o;
          }),
        },
        () => {
          setFieldValue(
            field+"uploads",
            this.state[field+'files']
              .filter((o) => o.status === "complete")
              .map((o) => o.filename)
          );
        }
      );
      return;
    }
    this.setState(
      {
        status: "importing",
        [field+"files"]: this.state[field+"files"].map((o) => {
          if (o === file) {
            o.status = "importing";
          }
          return o;
        }),
      },
      () => {
        this.doFileUpload(setFieldValue, field);
      }
    );
  };
  doFileUpload = (setFieldValue, field = '') => {
    this.setState({ isUploading: true });

    const file = this.state[field+"files"].find((o) => o.status === "importing");

    //ajax post request...
    let formData = new FormData();

    formData.append("file", file.file);
    formData.append("filename", file.file.name);

    axios
      .post(API_URL + "/induction/upload", formData, API_MULTIPART_CONFIG)
      .then((response) => {
        this.setState(
          {
            [field+"files"]: this.state[field+"files"].map((o) => {
              if (o === file) {
                o.filename = response.data.filename;
                o.status = "complete";
              }
              return o;
            }),
          },
          () => {
            setFieldValue(
              [field+"uploads"],
              this.state[field+"files"]
                .filter((o) => o.status === "complete")
                .map((o) => o.filename)
            );

            const nextFile = this.state[field+"files"].find(
              (o) => o.status === "pending"
            );
            this.uploadFile(nextFile, setFieldValue, field);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            [field+"files"]: this.state[field+"files"].map((o) => {
              if (o === file) {
                o.status = "error";
                o.error = error.message;
              }
              return o;
            }),
          },
          () => {
            const nextFile = this.state[field+"files"].find(
              (o) => o.status === "pending"
            );
            this.uploadFile(nextFile, setFieldValue, field);
          }
        );
      });
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div className="flex flex-col h-screen w-full items-center justify-center">
          <p>Loading...</p>
        </div>
      );
    }

    const account = this.state.subdomain;
    if (!account || (account !== "head-office" && account !== "northern")) {
      return <Redirect to="/" />;
    }

    if (this.state.submitted.status === "complete") {
      return (
        <div className="fixed inset-0 z-50 bg-white flex flex-col h-screen w-full items-center justify-center">
          <div className={"mb-6 text-green-600 px-2"}>
            <FontAwesomeIcon size={"4x"} icon={faCheckCircle} />
          </div>
          <p className="mb-3">
            Thank you, your induction information has been sent to our staff,
            <br />
            One of our staff members will be in touch shortly to discuss.
          </p>
        </div>
      );
    }

    return (
      <>
        {this.state.submitted.status === "submitting" && (
          <div className="fixed inset-0 z-50 bg-white flex flex-col h-screen w-full items-center justify-center">
            <div className={"mb-6 text-gray-400 px-2"}>
              <FontAwesomeIcon spin size={"4x"} icon={faSpinnerThird} />
            </div>
            <p className="mb-3">
              We’re procssing your request. Please be patient while we work with
              your data and files...
            </p>
            <p>
              This page will refresh when we have an update from the server. Do
              not close this page until complete!
            </p>
          </div>
        )}

        {this.state.submitted.status === "error" && (
          <div className="fixed inset-0 z-50 bg-white flex flex-col h-screen w-full items-center justify-center text-rose-600">
            <p className="mb-3">
              There appears to be a problem submitting your data to the server,
              please see error code below.
            </p>
            <p className="font-bold mb-4">{this.state.submitted.error}</p>
            <Button
              type="button"
              appearance="red"
              onClick={() =>
                this.setState({
                  submitted: {
                    error: false,
                    status: "pending",
                  },
                })
              }
            >
              Try Again
            </Button>
          </div>
        )}

        <div className="flex min-h-screen flex-col relative w-full">
          <div className="sticky top-0 z-10 h-24 w-full bg-white border-b border-gray-200">
            <div className="flex h-full items-center">
              <div className="px-8 lg:px-12">
                {account && account === "northern" ? (
                  <img src="/logo-northern-colour.png" width="150" alt="" />
                ) : (
                  <img src="/logo-colour.png" width="150" alt="" />
                )}
              </div>
            </div>
          </div>

          <div className="flex-1 flex flex-col md:flex-row relative w-full">
            <div className="md:sticky md:top-0 md:w-88 lg:w-120">
              <div className="p-4 md:p-8 lg:p-12">
                <h1 className="font-bold text-xl mb-2">Induction Form</h1>
                <p className="text-sm p-2 border border-gray-200 bg-gray-100 text-gray-700 rounded">
                  Please follow all steps and provide accurate information to
                  allow our staff to process your information as quickly as
                  possible
                  <span className="block pt-2 font-bold">
                    FAILURE TO DO THIS COULD LEAD TO DELAY IN PAYMENTS.
                  </span>
                </p>
                <Divider />
                <div className="hidden md:flex flex-1  flex-col">
                  {this.state.steps.map((step, i) => {
                    return (
                      <div
                        key={"step-" + i}
                        className="flex flex-row items-center mb-2"
                      >
                        {this.state.currentStep > i && (
                          <div className="flex items-center justify-center w-8 h-8 text-white bg-gray-900 border-gray-900 border rounded-full ">
                            <FontAwesomeIcon icon={faCheck} />
                          </div>
                        )}

                        {this.state.currentStep === i && (
                          <div className="flex items-center justify-center w-8 h-8 text-white bg-gray-900 border-gray-900 border rounded-full ">
                            {i + 1}
                          </div>
                        )}
                        {this.state.currentStep < i && (
                          <div className="flex items-center justify-center w-8 h-8 bg-gray-100 border-gray-300 border rounded-full ">
                            {i + 1}
                          </div>
                        )}
                        {this.state.currentStep >= i && (
                          <p className="ml-4">{step}</p>
                        )}
                        {this.state.currentStep < i && (
                          <p className="ml-4 text-gray-400">{step}</p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <Formik
              initialValues={{
                region: account,
                watched_video: false,
                first_name: "",
                middle_name: "",
                last_name: "",
                dob: "",
                email: "",
                address: "",
                postcode: "",
                // home_phone: "",
                mobile_phone: "",
                hiring_supervisor: "",
                ethnic_group: "",
                ethnic_other: "",
                nationality: "",
                right_to_work: '',
                right_to_work_evidence: '',
                marital_status: "",
                password: "",
                password_confirmation: "",

                ni_number: "",
                cisrs_number: "",
                utr_number: "",
                card_held: "",
                exiry_date: "",
                experience: "",
                employment_status: "",
                has_worked_for_db: "",

                accounts_name: "",
                account_numer: "",
                sort_code: "",
                bank_name: "",
                bank_reference: "",
                bank_address: "",

                ...medicalKeys,
                addtional_medicial_information: "",

                contact_name_1: "",
                contact_relationship_1: "",
                contact_phone_1: "",
                contact_name_2: "",
                contact_relationship_2: "",
                contact_phone_2: "",

                uploads: [],

                declaration: false,
                acknowledgement: false,
                confirm_standards: false,

                confirm_contract: false,
                recaptcha: "",
              }}
              validationSchema={ValidationSchema[this.state.currentStep]}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values) => {
                // same shape as initial values
                if (this.state.currentStep >= this.state.steps.length - 1) {
                  this.sendInduction(values);
                } else {
                  if (this.state.currentStep === 1) {
                    this.setState(
                      {
                        validatingEmail: true,
                      },
                      () => {
                        axios
                          .post(
                            API_URL + "/validate-email",
                            {
                              email: values.email,
                            },
                            API_CONFIG
                          )
                          .then((res) => {
                            this.setState({
                              validatingEmail: false,
                              emailError: false,
                              currentStep: this.state.currentStep + 1,
                            });
                          })
                          .catch((error) => {
                            console.log(error.response);
                            const message =
                              error.response.data &&
                                error.response.data.message &&
                                error.response.data.message.text
                                ? error.response.data.message.text
                                : error.response.statusText;

                            this.setState({
                              validatingEmail: false,
                              emailError: message,
                            });
                          });
                      }
                    );
                  } else {
                    this.setState({
                      currentStep: this.state.currentStep + 1,
                    });
                  }
                }
              }}
            >
              {({ errors, values, setFieldValue }) => (
                <Form className="flex-1 border-l border-gray-200">
                  <div className="p-4 md:p-8 lg:p-12 mb-8">
                    <p className="font-bold mb-2">
                      {this.state.currentStep + 1}.{" "}
                      {this.state.steps[this.state.currentStep]}
                    </p>
                    {this.state.currentStep === 0 && (
                      <>
                        {account && account === "northern" ? (
                          <div className="aspect-w-16 aspect-h-9 mb-4">
                            <iframe
                              src="https://player.vimeo.com/video/558467380"
                              title="D&B Northern Induction Video"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          </div>
                        ) : (
                          <div className="aspect-w-16 aspect-h-9 mb-4">
                            <iframe
                              src="https://player.vimeo.com/video/560431443"
                              title="D&B Induction Video"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          </div>
                        )}

                        <div className="mb-3 p-4 border-gray-200 bg-gray-100 border rounded">
                          <Label className="w-full block">
                            <Field
                              type="checkbox"
                              name={"watched_video"}
                              className="mr-2"
                            />
                            I confirm I have watched the full induction video
                          </Label>
                        </div>
                        <InputError>
                          {errors.watched_video ? errors.watched_video : null}
                        </InputError>
                      </>
                    )}

                    {this.state.currentStep === 1 && (
                      <>
                        <div className="md:grid md:grid-cols-3 md:gap-4">
                          <div className="mb-3">
                            <Label>First Name</Label>
                            <Input
                              autoFocus
                              hasError={errors.first_name}
                              type="text"
                              name="first_name"
                            />
                            <InputError>
                              {errors.first_name ? errors.first_name : null}
                            </InputError>
                          </div>
                          <div className="mb-3">
                            <Label>Middle Name</Label>
                            <Input
                              hasError={errors.middle_name}
                              type="text"
                              name="middle_name"
                            />
                            <InputError>
                              {errors.middle_name ? errors.middle_name : null}
                            </InputError>
                          </div>
                          <div className="mb-3">
                            <Label>Last Name</Label>
                            <Input
                              hasError={errors.last_name}
                              type="text"
                              name="last_name"
                            />
                            <InputError>
                              {errors.last_name ? errors.last_name : null}
                            </InputError>
                          </div>
                        </div>
                        <div className="mb-3">
                          <Label>Date of Birth</Label>
                          <Input hasError={errors.dob} type="text" name="dob" />
                          <InputError>
                            {errors.dob ? errors.dob : null}
                          </InputError>
                        </div>
                        <div className="mb-3">
                          <Label>Email Address</Label>
                          <Input
                            hasError={errors.email || this.state.emailError}
                            type="email"
                            name="email"
                          />
                          <InputError>
                            {errors.email
                              ? errors.email
                              : this.state.emailError
                                ? this.state.emailError
                                : null}
                          </InputError>
                        </div>
                        <Divider />
                        <div className="mb-3">
                          <Textarea
                            label="Address"
                            hasError={errors.address}
                            rows={4}
                            maxLength={200}
                            name="address"
                          />
                          <InputError>
                            {errors.address ? errors.address : null}
                          </InputError>
                        </div>
                        <div className="mb-3">
                          <Label>Post Code</Label>
                          <Input
                            hasError={errors.postcode}
                            type="text"
                            name="postcode"
                          />
                          <InputError>
                            {errors.postcode ? errors.postcode : null}
                          </InputError>
                        </div>
                        <Divider />
                        <div className="md:grid md:grid-cols-2 md:gap-4">
                          <div className="mb-3">
                            <Label>Mobile Phone Number</Label>
                            <Input
                              hasError={errors.mobile_phone}
                              type="text"
                              name="mobile_phone"
                            />
                            <InputError>
                              {errors.mobile_phone ? errors.mobile_phone : null}
                            </InputError>
                          </div>
                          <div className="mb-3">
                            <Label>Hiring Area supervisor?</Label>
                            <Input
                              hasError={errors.hiring_supervisor}
                              type="text"
                              name="hiring_supervisor"
                            />
                            <InputError>
                              {errors.hiring_supervisor
                                ? errors.hiring_supervisor
                                : null}
                            </InputError>
                          </div>
                        </div>
                        <Divider />

                        <div className="md:grid md:grid-cols-2 md:gap-4">
                          <div className="mb-3">
                            <Label>What is your ethnic group?</Label>
                            <InputRadios hasError={errors.ethnic_group}>
                              <label className="mr-4">
                                <Field
                                  type="radio"
                                  name="ethnic_group"
                                  value="White European"
                                  className="mr-2"
                                />
                                White European
                              </label>
                              <label className="mr-4">
                                <Field
                                  type="radio"
                                  name="ethnic_group"
                                  value="Afro Caribbean"
                                  className="mr-2"
                                />
                                Afro Caribbean
                              </label>
                              <label className="mr-4">
                                <Field
                                  type="radio"
                                  name="ethnic_group"
                                  value="Asian"
                                  className="mr-2"
                                />
                                Asian
                              </label>
                              <label className="mr-4">
                                <Field
                                  type="radio"
                                  name="ethnic_group"
                                  value="Other"
                                  className="mr-2"
                                />
                                Other
                              </label>
                            </InputRadios>
                            <InputError>
                              {errors.ethnic_group ? errors.ethnic_group : null}
                            </InputError>
                          </div>
                          <div className="mb-3">
                            <Label>Other – please specify</Label>
                            <Input
                              hasError={errors.ethnic_other}
                              type="text"
                              name="ethnic_other"
                            />
                            <InputError>
                              {errors.ethnic_other ? errors.ethnic_other : null}
                            </InputError>
                          </div>
                        </div>

                        <div className="md:grid md:grid-cols-2 md:gap-4">
                          <div className="mb-3">
                            <Label>What is your nationality?</Label>
                            <Input
                              hasError={errors.nationality}
                              type="text"
                              name="nationality"
                            />
                            <InputError>
                              {errors.nationality ? errors.nationality : null}
                            </InputError>
                          </div>
                          <div className="mb-3">
                            <Label>What is your marital status?</Label>
                            <Input
                              hasError={errors.marital_status}
                              type="text"
                              name="marital_status"
                            />
                            <InputError>
                              {errors.marital_status
                                ? errors.marital_status
                                : null}
                            </InputError>
                          </div>
                        </div>
                        <Divider />

                        <div className="">
                          <div className="mb-3">
                            <Label>Do you have right to work in the UK?</Label>
                            <InputRadios hasError={errors.right_to_work}>
                              <label className="mr-4">
                                <Field
                                  type="radio"
                                  name="right_to_work"
                                  value="Yes"
                                  className="mr-2"
                                />
                                Yes
                              </label>
                              <label className="mr-4">
                                <Field
                                  type="radio"
                                  name="right_to_work"
                                  value="No"
                                  className="mr-2"
                                />
                                No
                              </label>

                            </InputRadios>
                          </div>
                        </div>

                        <div className="">
                          <div className="mb-3">
                            <Label>If you are not a British or Irish Citizen please provide evidence of documentation and/or share code?</Label>
                          </div>
                            
                          <div className="mt-4 mb-3">

                            <div className="">
                            {this.state.right_to_work_files.length > 0 &&
                              this.state.right_to_work_files.map((file, i) => {
                                console.log(file);
                                if (!file || !file.file) {
                                  return null;
                                }
                                return (
                                  <div
                                    key={"file-" + i}
                                    className={
                                      "relative py-1 px-2 flex flex-row bg-gray-100 border-b border-gray-300 last:border-0 last:mb-4"
                                    }
                                  >
                                    <p className="flex-1">{file.file.name}</p>
                                    {file.status === "error" ? (
                                      <span className="text-error">
                                        {file.error}
                                      </span>
                                    ) : (
                                      <span className="text-gray-500">
                                        {file.status}
                                      </span>
                                    )}

                                    {(file.status === "error" ||
                                      file.status === "pending" ||
                                      file.status === "complete") && (
                                        <button
                                          type="button"
                                          className={
                                            "ml-2 text-gray-400 hover:text-gray-700 focus:outline-none px-2"
                                          }
                                          onClick={() =>
                                            this.removeFile(i, setFieldValue)
                                          }
                                        >
                                          <FontAwesomeIcon icon={faTimesCircle} />
                                        </button>
                                      )}
                                    {file.status === "importing" && (
                                      <div className={"ml-2 text-gray-400 px-2"}>
                                        <FontAwesomeIcon icon={faSpinnerThird} />
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
</div>
                            <label
                              forhtml="file-upload"
                              disabled={this.state.status === "importing"}
                              className="mt-2 relative py-2 px-4 rounded-sm focus:outline-none focus:shadow-outline disabled:opacity-25 bg-gray-900 font-bold text-white hover:bg-gray-700 focus:bg-gray-500  "
                            >
                              <span>Upload a file</span>
                              <input
                                id="right_to_work_file-upload"
                                name="right_to_work_file-upload"
                                disabled={this.state.status === "importing"}
                                multiple
                                type="file"
                                className="sr-only"
                                onChange={(event) =>
                                  this.setEvidenceFiles(event.target.files, setFieldValue)
                                }
                              />
                            </label>
                            <div className="text-sm mt-2 text-gray-500">
                              Accepts jpg, png or gif files.
                            </div>
                            <InputError>
                              {errors.right_to_work_uploads && typeof errors.right_to_work_uploads === "string"
                                ? errors.right_to_work_uploads
                                : null}
                            </InputError>
                          </div>
                          <div className="mb-3">
                          <Textarea
                            label="Evidence of Documentation"
                            hasError={errors.right_to_work_comments}
                            rows={4}
                            maxLength={200}
                            name="right_to_work_comments"
                          />
                          <InputError>
                            {errors.right_to_work_comments ? errors.right_to_work_comments : null}
                          </InputError>
                        </div>

                        </div>

                        <Divider />
                        <p className="mb-3 text-sm">
                          Once your induction has been approved, you’ll be able
                          to login to the D&B Portal to submit weekly
                          timesheets. Please provide a password below for your
                          access.
                        </p>
                        <div className="md:grid md:grid-cols-2 md:gap-4">
                          <div className="mb-3">
                            <div className="flex flex-row justify-between relative">
                              <Label>Password</Label>
                              <button
                                type="button"
                                className="absolute top-0 right-0 p-px text-gray-500 focus:outline-none focus:shadow-outline disabled:opacity-25"
                                onClick={() =>
                                  this.setState({
                                    showPassword: !this.state.showPassword,
                                  })
                                }
                              >
                                <FontAwesomeIcon
                                  fixedWidth
                                  className="text-gray-400"
                                  icon={
                                    this.state.showPassword ? faEyeSlash : faEye
                                  }
                                />
                              </button>
                            </div>
                            <Input
                              hasError={errors.password}
                              type={
                                this.state.showPassword ? "text" : "password"
                              }
                              name="password"
                            />
                            <InputError>
                              {errors.password ? errors.password : null}
                            </InputError>
                          </div>
                          <div className="mb-3">
                            <Label>Confirm Password</Label>
                            <Input
                              hasError={errors.password_confirmation}
                              type={
                                this.state.showPassword ? "text" : "password"
                              }
                              name="password_confirmation"
                            />
                            <InputError>
                              {errors.password_confirmation
                                ? errors.password_confirmation
                                : null}
                            </InputError>
                          </div>
                        </div>
                        <Divider />
                      </>
                    )}

                    {this.state.currentStep === 2 && (
                      <>
                        <div className="mb-3">
                          <Label>National Insurance Number</Label>
                          <Input
                            autoFocus
                            hasError={errors.ni_number}
                            type="text"
                            name="ni_number"
                          />
                          <InputError>
                            {errors.ni_number ? errors.ni_number : null}
                          </InputError>
                        </div>
                        <Divider />

                        <div className="mb-3">
                          <Label>CISRS Reg Number</Label>
                          <Input
                            hasError={errors.cisrs_number}
                            type="text"
                            name="cisrs_number"
                          />
                          <InputError>
                            {errors.cisrs_number ? errors.cisrs_number : null}
                          </InputError>
                        </div>

                        <div className="mb-3">
                          <Label>UTR Number</Label>
                          <Input
                            hasError={errors.utr_number}
                            type="text"
                            name="utr_number"
                          />
                          <InputError>
                            {errors.utr_number ? errors.utr_number : null}
                          </InputError>
                        </div>

                        <div className="mb-3">
                          <Label>Card Held</Label>
                          <Input
                            hasError={errors.card_held}
                            type="text"
                            name="card_held"
                          />
                          <InputError>
                            {errors.card_held ? errors.card_held : null}
                          </InputError>
                        </div>

                        <div className="mb-3">
                          <Label>Card Expiry</Label>
                          <Input
                            hasError={errors.card_expiry}
                            type="text"
                            name="card_expiry"
                          />
                          <InputError>
                            {errors.card_expiry ? errors.card_expiry : null}
                          </InputError>
                        </div>
                        <Divider />

                        <div className="mb-3">
                          <Textarea
                            label="Brief description of experience"
                            hasError={errors.experience}
                            rows={4}
                            maxLength={200}
                            name="experience"
                          />
                          <InputError>
                            {errors.experience ? errors.experience : null}
                          </InputError>
                        </div>
                        <Divider />

                        <div className="mb-3">
                          <Label>Previous employment status</Label>
                          <InputRadios hasError={errors.employment_status}>
                            <label className="mr-4">
                              <Field
                                type="radio"
                                name="employment_status"
                                value="Employed"
                                className="mr-2"
                              />
                              Employed
                            </label>
                            <label className="mr-4">
                              <Field
                                type="radio"
                                name="employment_status"
                                value="Unemployed"
                                className="mr-2"
                              />
                              Unemployed
                            </label>
                            <label className="mr-4">
                              <Field
                                type="radio"
                                name="employment_status"
                                value="Self-employed"
                                className="mr-2"
                              />
                              Self-employed
                            </label>
                          </InputRadios>
                          <InputError>
                            {errors.employment_status
                              ? errors.employment_status
                              : null}
                          </InputError>
                        </div>

                        <div className="mb-3">
                          <Label>
                            Have you worked for D&B Scaffolding before?
                          </Label>
                          <InputRadios hasError={errors.has_worked_for_db}>
                            <label className="mr-4">
                              <Field
                                type="radio"
                                name="has_worked_for_db"
                                value="yes"
                                className="mr-2"
                              />
                              Yes
                            </label>
                            <label className="mr-4">
                              <Field
                                type="radio"
                                name="has_worked_for_db"
                                value="no"
                                className="mr-2"
                              />
                              No
                            </label>
                          </InputRadios>
                          <InputError>
                            {errors.has_worked_for_db
                              ? errors.has_worked_for_db
                              : null}
                          </InputError>
                        </div>

                        <Divider />
                      </>
                    )}

                    {this.state.currentStep === 3 && (
                      <>
                        <div className="mb-3">
                          <Label>Account Holder’s Name</Label>
                          <Input
                            autoFocus
                            hasError={errors.accounts_name}
                            type="text"
                            name="accounts_name"
                          />
                          <InputError>
                            {errors.accounts_name ? errors.accounts_name : null}
                          </InputError>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:gap-4">
                          <div className="mb-3">
                            <Label>Account Number</Label>
                            <Input
                              hasError={errors.account_number}
                              type="text"
                              name="account_number"
                              maxLength={8}
                            />
                            <InputError>
                              {errors.account_number
                                ? errors.account_number
                                : null}
                            </InputError>
                          </div>

                          <div className="mb-3">
                            <Label>Sort Code</Label>
                            <Input
                              hasError={errors.sort_code}
                              type="text"
                              name="sort_code"
                            />
                            <InputError>
                              {errors.sort_code ? errors.sort_code : null}
                            </InputError>
                          </div>
                        </div>

                        <Divider />

                        <div className="mb-3">
                          <Label>Name of Bank or Building Society</Label>
                          <Input
                            hasError={errors.bank_name}
                            type="text"
                            name="bank_name"
                          />
                          <InputError>
                            {errors.bank_name ? errors.bank_name : null}
                          </InputError>
                        </div>

                        <div className="mb-3">
                          <Label>
                            Building Society Reference{" "}
                            <span className="text-gray-500">
                              (if applicable)
                            </span>
                          </Label>
                          <Input
                            hasError={errors.bank_reference}
                            type="text"
                            name="bank_reference"
                          />
                          <InputError>
                            {errors.bank_reference
                              ? errors.bank_reference
                              : null}
                          </InputError>
                        </div>

                        <div className="mb-3">
                          <Textarea
                            label="Bank’s Address"
                            hasError={errors.bank_address}
                            rows={4}
                            maxLength={200}
                            name="bank_address"
                          />
                          <InputError>
                            {errors.bank_address ? errors.bank_address : null}
                          </InputError>
                        </div>

                        <Divider />
                      </>
                    )}

                    {this.state.currentStep === 4 && (
                      <>
                        <p className="mb-3 text-sm">
                          The health and safety of all our subcontractors is of
                          prime importance to the Company and for the Company to
                          ensure a safe and healthy workplace. We ask that all
                          subcontractors complete this questionnaire to help us
                          with our health and safety at work risk assessments
                          and to deal with any medical emergencies appropriately
                          taking into consideration individual’s circumstances.{" "}
                        </p>

                        <p className="mb-3 text-sm">
                          The information provided on this form will be treated
                          with the strictest confidence and in accordance to the
                          Data Protection Act 2018 and Equality Act 2010.
                        </p>

                        <ol className="mt-3 list-decimal pl-8  border-t border-gray-200">
                          {medicalQuestions.map((item, i) => {
                            return (
                              <li
                                key={"question-" + i}
                                className="border-b border-gray-200"
                              >
                                <div className="flex flex-row">
                                  <p className="p-3 flex-1">{item.question}</p>
                                  {!item.questions && (
                                    <>
                                      <Label className="p-3 border-l border-gray-200">
                                        <Field
                                          type="radio"
                                          name={"medical_" + i}
                                          value="yes"
                                          className="mr-2"
                                        />
                                        Yes
                                      </Label>
                                      <Label className="p-3 border-l border-gray-200">
                                        <Field
                                          type="radio"
                                          name={"medical_" + i}
                                          value="no"
                                          className="mr-2"
                                        />
                                        No
                                      </Label>
                                    </>
                                  )}
                                </div>
                                <InputError>
                                  {errors["medical_" + i]
                                    ? errors["medical_" + i]
                                    : null}
                                </InputError>
                                {item.questions && (
                                  <ol
                                    className=" pl-8 border-t border-gray-200"
                                    style={{ listStyleType: "lower-alpha" }}
                                  >
                                    {item.questions.map((subitem, k) => {
                                      return (
                                        <li
                                          key={"medical_" + i + "_" + k}
                                          className="border-b border-gray-200 last:border-transparent"
                                        >
                                          <div className="flex flex-row">
                                            <p className="p-1 flex-1">
                                              {subitem.question}
                                            </p>
                                            <Label className="py-1 px-3 border-l border-gray-200">
                                              <Field
                                                type="radio"
                                                name={"medical_" + i + "_" + k}
                                                value="yes"
                                                className="mr-2"
                                              />
                                              Yes
                                            </Label>
                                            <Label className="py-1 px-3 border-l border-gray-200">
                                              <Field
                                                type="radio"
                                                name={"medical_" + i + "_" + k}
                                                value="no"
                                                className="mr-2"
                                              />
                                              No
                                            </Label>
                                          </div>
                                          <InputError>
                                            {errors["medical_" + i + "_" + k]
                                              ? errors["medical_" + i + "_" + k]
                                              : null}
                                          </InputError>
                                        </li>
                                      );
                                    })}
                                  </ol>
                                )}
                              </li>
                            );
                          })}
                        </ol>

                        <Divider />

                        <div className="mb-3">
                          <Textarea
                            label="If you have circled any answers yes from questions 1 to 14 or wish to inform us of any other issue regarding your health please give details below;"
                            hasError={errors.addtional_medicial_information}
                            rows={6}
                            maxLength={200}
                            name="addtional_medicial_information"
                          />
                          <InputError>
                            {errors.addtional_medicial_information
                              ? errors.addtional_medicial_information
                              : null}
                          </InputError>
                        </div>

                        <Divider />
                      </>
                    )}

                    {this.state.currentStep === 5 && (
                      <>
                        <p className="mb-3 text-sm">
                          In case of an emergency please state the details of 2
                          people we can contact on your behalf.
                        </p>
                        <Divider />

                        <div className="mb-3">
                          <Label>
                            Emergeny Contact Name{" "}
                            <span className="font-bold">(Primary)</span>
                          </Label>
                          <Input
                            autoFocus
                            hasError={errors.contact_name_1}
                            type="text"
                            name="contact_name_1"
                          />
                          <InputError>
                            {errors.contact_name_1
                              ? errors.contact_name_1
                              : null}
                          </InputError>
                        </div>
                        <div className="mb-3">
                          <Label>Relationship</Label>
                          <Input
                            hasError={errors.contact_relationship_1}
                            type="text"
                            name="contact_relationship_1"
                          />
                          <InputError>
                            {errors.contact_relationship_1
                              ? errors.contact_relationship_1
                              : null}
                          </InputError>
                        </div>
                        <div className="mb-3">
                          <Label>Contact Phone Number</Label>
                          <Input
                            hasError={errors.contact_phone_1}
                            type="text"
                            name="contact_phone_1"
                          />
                          <InputError>
                            {errors.contact_phone_1
                              ? errors.contact_phone_1
                              : null}
                          </InputError>
                        </div>

                        <Divider />

                        <div className="mb-3">
                          <Label>
                            Emergeny Contact Name{" "}
                            <span className="font-bold">(Secondary)</span>
                          </Label>
                          <Input
                            hasError={errors.contact_name_2}
                            type="text"
                            name="contact_name_2"
                          />
                          <InputError>
                            {errors.contact_name_2
                              ? errors.contact_name_2
                              : null}
                          </InputError>
                        </div>
                        <div className="mb-3">
                          <Label>Relationship</Label>
                          <Input
                            hasError={errors.contact_relationship_2}
                            type="text"
                            name="contact_relationship_2"
                          />
                          <InputError>
                            {errors.contact_relationship_2
                              ? errors.contact_relationship_2
                              : null}
                          </InputError>
                        </div>
                        <div className="mb-3">
                          <Label>Contact Phone Number</Label>
                          <Input
                            hasError={errors.contact_phone_2}
                            type="text"
                            name="contact_phone_2"
                          />
                          <InputError>
                            {errors.contact_phone_2
                              ? errors.contact_phone_2
                              : null}
                          </InputError>
                        </div>

                        <Divider />
                      </>
                    )}

                    {this.state.currentStep === 6 && (
                      <>
                        <div className="p-4 rounded border border-yellow-300 bg-yellow-100 text-yellow-800 mb-3">
                          Please take photos of required documents /
                          certificates and upload accordingly. This includes but
                          is not limited to your CISRS, licences and/or
                          insurance documents.
                          <hr className="border-yellow-300 my-2" />
                          For CISRS please include photos for the front and
                          back.
                        </div>
                        <Divider />
                        {!this.state.files.length && (
                          <p className="mb-3 text-gray-500">
                            You have not uploaded any files.
                          </p>
                        )}
                        {this.state.files.length > 0 &&
                          this.state.files.map((file, i) => {
                            console.log(file);
                            if (!file || !file.file) {
                              return null;
                            }
                            return (
                              <div
                                key={"file-" + i}
                                className={
                                  "relative py-1 px-2 flex flex-row bg-gray-100 border-b border-gray-300 last:border-0"
                                }
                              >
                                <p className="flex-1">{file.file.name}</p>
                                {file.status === "error" ? (
                                  <span className="text-error">
                                    {file.error}
                                  </span>
                                ) : (
                                  <span className="text-gray-500">
                                    {file.status}
                                  </span>
                                )}

                                {(file.status === "error" ||
                                  file.status === "pending" ||
                                  file.status === "complete") && (
                                    <button
                                      type="button"
                                      className={
                                        "ml-2 text-gray-400 hover:text-gray-700 focus:outline-none px-2"
                                      }
                                      onClick={() =>
                                        this.removeFile(i, setFieldValue)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faTimesCircle} />
                                    </button>
                                  )}
                                {file.status === "importing" && (
                                  <div className={"ml-2 text-gray-400 px-2"}>
                                    <FontAwesomeIcon icon={faSpinnerThird} />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        <Divider />
                        <label
                          forhtml="file-upload"
                          disabled={this.state.status === "importing"}
                          className="relative py-2 px-4 rounded-sm focus:outline-none focus:shadow-outline disabled:opacity-25 bg-gray-900 font-bold text-white hover:bg-gray-700 focus:bg-gray-500  "
                        >
                          <span>Upload a file</span>
                          <input
                            id="file-upload"
                            name="file-upload"
                            disabled={this.state.status === "importing"}
                            multiple
                            type="file"
                            className="sr-only"
                            onChange={(event) =>
                              this.setFiles(event.target.files, setFieldValue)
                            }
                          />
                        </label>
                        <div className="text-sm mt-2 text-gray-500">
                          Accepts jpg, png or gif files.
                        </div>

                        <Divider />
                        <InputError>
                          {errors.uploads && typeof errors.uploads === "string"
                            ? errors.uploads
                            : null}
                        </InputError>
                      </>
                    )}

                    {this.state.currentStep === 7 && (
                      <>
                        <Label>Declaration</Label>
                        <div className="mb-3 p-4 border-gray-200 bg-gray-100 border rounded">
                          I have read and understood the above questions and
                          have answered them truthfully to the best of my
                          knowledge and agree that in an emergency the Company
                          can contact the people stated on this form and provide
                          this information to a member of the
                          medical/police/fire emergency services where
                          necessary. If any details in this form changes I
                          accept it is my responsibility to notify the Company
                          accordingly.
                          <div className="border-t border-gray-200 my-4"></div>
                          <Label className="w-full block">
                            <Field
                              type="checkbox"
                              name={"declaration"}
                              className="mr-2"
                            />
                            I agree to the above declaration
                          </Label>
                        </div>
                        <InputError>
                          {errors.declaration ? errors.declaration : null}
                        </InputError>

                        <Label>Acknowledgement </Label>
                        <div className="mb-3 p-4 border-gray-200 bg-gray-100 border rounded">
                          I acknowledge that I have received a D&B induction and
                          confirm the following:
                          <ul className="mt-3 list-disc pl-4">
                            <li>
                              I confirm that I am competent to undertake all
                              duties and tasks safely in accordance with
                              training and skills as instructed and directed by
                              supervision.
                            </li>
                            <li>
                              I confirm that I understand and will work in
                              accordance with the risk assessments, method
                              statements & all other content of the HSEQ safety
                              file on site.
                            </li>
                            <li>
                              I confirm that if I suffer an accident whilst
                              on-site, I will inform the D&B Site Supervisor,
                              H&S manager/contracts manager and site manager
                              immediately and adhere with his/her further
                              instruction.
                            </li>
                            <li>
                              I confirm that I understand that a safe working
                              environment is more important than project
                              delivery and consequently I will stop work and
                              report to D&B’s site supervisor & or H&S manager
                              where: I feel that I am being exposed to personal
                              risk. My assigned duties are exposing others to
                              risk. I observe a potentially dangerous, act,
                              occurrence or situation I.E. slips, trips etc. I
                              am not certain what work I should be doing. I
                              confirm that I will comply with NASC guidance
                              including TG20:21, SG4:22 & SG6:22. I confirm that
                              I have completed a D&B medical Questionnaire.
                            </li>
                            <li>
                              I confirm that any work equipment which I supply
                              will be fit for purpose, be in good condition and
                              be tested where required e.g. PAT testing.
                            </li>
                            <li>
                              I acknowledge any PPE or tools provided by D&B
                              would have to be returned if I leave the company.
                              I am aware if I don’t return the above items I
                              will have the purchase value deducted from my
                              final wages.
                            </li>
                          </ul>
                          <div className="border-t border-gray-200 my-4"></div>
                          <Label className="w-full block">
                            <Field
                              type="checkbox"
                              name={"acknowledgement"}
                              className="mr-2"
                            />
                            I agree to the above acknowledgement
                          </Label>
                        </div>
                        <InputError>
                          {errors.acknowledgement
                            ? errors.acknowledgement
                            : null}
                        </InputError>

                        <Label>D&B Standards</Label>

                        <div className="mb-3 p-2 border-gray-200 bg-gray-100 border rounded flex flex-col ">
                          <div className="text-red-600 mb-2">
                            You are required to read the below D&B Standards
                            document before accepting.
                          </div>
                          <div className="flex">
                            <Button
                              appearance="dark"
                              as="link"
                              href={
                                account && account === "northern"
                                  ? API_URL +
                                  "/storage/documents/D&B Scaffolding (Northern) Ltd Standards.pdf"
                                  : API_URL +
                                  "/storage/documents/D&B Scaffolding Ltd Standards.pdf"
                              }
                              target="_blank"
                            >
                              Read Document
                            </Button>
                          </div>
                          <Label className="flex-1 p-2 block">
                            <Field
                              type="checkbox"
                              name={"confirm_standards"}
                              className="mr-2"
                            />
                            I confirm I have read and understood the D&B
                            Standards and will implement these where possible.
                          </Label>
                        </div>
                        <InputError>
                          {errors.confirm_standards
                            ? errors.confirm_standards
                            : null}
                        </InputError>
                      </>
                    )}
                    {this.state.currentStep === 8 && (
                      <>
                        {this.state.captchaKey && (
                          <>
                            <p className="pt-4 font-bold">
                              THIS CONTRACT FOR SERVICES dated{" "}
                              <span className="underline">
                                {DateTime.local().toFormat("dd LLLL yyyy")}
                              </span>{" "}
                              made between: -{" "}
                            </p>
                            <p className="pb-2 font-bold">
                              {account && account === "northern"
                                ? "D & B Scaffolding (Northern) Ltd, of Unit 10, Willow Road, The Lakes Business Park, Fenstanton, PE28 9RB"
                                : "D & B Scaffolding Ltd, Montpelier House, Blasford Hill, Little Waltham, Chelmsford, Essex CM3 3PG"}{" "}
                              (&ldquo;D&amp;B&rdquo;) and{" "}
                              <span className="underline">
                                {values.first_name} {values.middle_name}{" "}
                                {values.last_name}
                              </span>
                              (&ldquo;the Subcontractor&rdquo;)
                            </p>
                            <p className="pt-8 pb-4 font-bold text-gray-600">
                              BACKGROUND
                            </p>
                            <ol
                              className="pl-8"
                              style={{ listStyleType: "lower-alpha" }}
                            >
                              <li>
                                D&amp;B&rsquo;s business is in erecting and
                                dismantling scaffolding and ancillary services.
                                &nbsp;D&amp;B tenders for and is appointed by
                                its clients to complete a project for specified
                                works at a certain site or location (&ldquo;the
                                Works&rdquo;).
                              </li>
                              <li>
                                The Subcontractor has skills and abilities which
                                may from time to time be available to D&amp;B
                                (&ldquo;the Services&rdquo;).
                              </li>
                              <li>
                                D&amp;B and the Subcontractor agree that if the
                                Subcontractor offers to make his services
                                available to D&amp;B and is engaged by D&amp;B,
                                the terms and conditions in this Contract for
                                Services shall apply.
                              </li>
                              <li>
                                It is the intention of the parties that when the
                                Subcontractor provides the Services to D&amp;B
                                for particular Services, such provision of
                                Services shall constitute a separate and
                                distinctive engagement under this Contract for
                                Services. Unless varied or amended in writing,
                                these terms and conditions shall apply for each
                                engagement.
                              </li>
                            </ol>
                            <p className="pt-8 pb-4 font-bold text-gray-600">
                              OPERATIVE PROVISIONS&nbsp;
                            </p>
                            <ol className="list-decimal pl-8">
                              <li>
                                D&amp;B is not obliged to offer Works to the
                                Subcontractor, neither is the Subcontractor
                                obliged to accept any work offered. &nbsp;The
                                Subcontractor is not obliged to make his
                                Services available at any time.
                                &nbsp;Specifically both parties agree that they
                                do not intend to create or imply any mutuality
                                of obligations at any time, either during or in
                                between any individual engagement.
                              </li>
                              <li>
                                The Subcontractor shall act in a professional
                                workmanlike way at all times while carrying out
                                the Services for D&amp;B. &nbsp;In particular
                                all Services will be provided in accordance with
                                NASC good practice guidance TG20:13 and SG4:22.
                              </li>
                              <li>
                                The specification of the Services will be set
                                out in design drawings or plans provided by
                                D&amp;B or by D&amp;B&rsquo;s client. &nbsp;The
                                Subcontractor is free to use his own initiative
                                as to how best to complete the Services and has
                                the flexibility to arrange how and when the
                                Services are carried out, always provided that
                                the specification is met, and the Subcontractor
                                does not unreasonably interfere with or delay
                                other works being carried on by or on behalf of
                                D&amp;B or with any site restrictions in place
                                on a particular site or Works.&nbsp;
                              </li>
                              <li>
                                The Subcontractor is free to provide any
                                services to any other party at the same time as
                                being engaged by D&amp;B and D&amp;B
                                acknowledges that it will not have first call on
                                the services of the Subcontractor in priority to
                                any third party.
                              </li>
                              <li>
                                Whilst certain tools and equipment may be
                                available on site the Subcontractor is
                                responsible for providing his own equipment and
                                tools for the purposes of providing the
                                Services, in particular spanners and levels.
                                &nbsp;The Subcontractor is also responsible for
                                providing his own boots, overalls and any
                                waterproof clothing required. &nbsp;Any other
                                personal protective clothing and safety
                                equipment, for example harnesses, will be
                                provided by D&amp;B.
                              </li>
                              <li>
                                All materials will be provided by D&amp;B.
                              </li>
                              <li>
                                The Subcontractor accepts that he is responsible
                                for the Services and that he is responsible for
                                covering his own risk and the risk of any
                                substitute or hired assistant, with a suitable
                                policy of insurance as he deems necessary.
                                &nbsp;It is the intention of D&amp;B to cover
                                its own risk in relation to Services provided by
                                the Subcontractor with D&amp;B&rsquo;s own
                                policy of insurance.
                              </li>
                              <li>
                                The Subcontractor will be responsible for the
                                initial and ongoing costs of registration and
                                training in relation to the CISRS and CSCS and
                                any other training costs necessary to perform
                                the Services. &nbsp;D&amp;B operates a policy of
                                encouraging and supporting training within the
                                construction industry. Details of
                                D&amp;B&rsquo;s policy and its application to
                                subcontractors are available on request from
                                D&amp;B.
                              </li>
                            </ol>
                            <p className="pt-8 pb-4 font-bold text-gray-600">
                              PAYMENT FOR THE SERVICES&nbsp;
                            </p>
                            <ol start="9" className="list-decimal pl-8">
                              <li>
                                Formal written tenders will not be required.
                                &nbsp;D&amp;B operates a schedule of price work
                                rates which will form the basis of the rate for
                                the Services. &nbsp;The Subcontractor will be
                                paid on a weekly basis by D&amp;B subject to
                                satisfactory completion of the Works.
                              </li>
                              <li>
                                Unless VAT registered, the Subcontractor is not
                                obliged to raise an invoice for the Services.
                                <br />
                                D&amp;B will provide a self-billing statement to
                                the Subcontractor in accordance with the
                                requirements of the self-employed Construction
                                Industry Scheme, which shall act as a
                                self-billed invoice. &nbsp;This may be sent
                                electronically to The Subcontractor and The
                                Subcontractor hereby consents to the use of
                                electronic communications for this
                                purpose.&nbsp;
                              </li>
                              <li>
                                The Subcontractor is responsible for all his
                                travelling expenses to and from any location
                                where he has been engaged to provide the
                                Services.
                              </li>
                              <li>
                                Any defective work that D&amp;B reasonably
                                determines has been caused by the Subcontractor,
                                or by any substitute or hired assistant working
                                for the Subcontractor, will be corrected by the
                                Subcontractor at his own cost and in his own
                                time.
                              </li>
                              <li>
                                D&amp;B and the Subcontractor are subject to the
                                Construction Industry Scheme. &nbsp;The
                                Subcontractor will provide to D&amp;B at the
                                earliest opportunity sufficient information to
                                enable D&amp;B to verify the
                                Subcontractor&rsquo;s payment status with HM
                                Revenue &amp; Customs. The responsibility for
                                the accuracy of this information rests with the
                                Subcontractor and the Subcontractor will not be
                                entitled to receive any payment under this
                                contract until this information has been
                                provided to D&amp;B.
                              </li>
                              <li>
                                The Subcontractor is responsible for his own
                                National Insurance contributions.
                              </li>
                              <li>
                                As an independent business, the Subcontractor
                                agrees that he is not entitled to holiday pay,
                                sick pay or any other payment for periods when
                                the Services are not provided to D&amp;B in any
                                circumstances; nor to participate in any pension
                                arrangements operated by D&amp;B.&nbsp;
                              </li>
                              <li>
                                The Subcontractor is not entitled to participate
                                in D&amp;B&rsquo;s grievance and disciplinary
                                procedure.
                              </li>
                              <li>
                                The Subcontractor will not be entitled to
                                receive payment for Services cancelled or where
                                a site is closed, for example by reason of
                                inclement weather.
                              </li>
                            </ol>
                            <p className="pt-8 pb-4 font-bold text-gray-600">
                              HEALTH AND SAFETY&nbsp;
                            </p>
                            <ol start="18" className="list-decimal pl-8">
                              <li>
                                In the interests of Health and Safety
                                obligations imposed on D&amp;B, the
                                Subcontractor agrees to comply with all
                                reasonable operational rules relating to working
                                hours, site security and safety.
                              </li>
                              <li>
                                The parties acknowledge that it may be necessary
                                for health and safety reasons for the
                                Subcontractor to be identifiable on site whether
                                evidenced by security passes or on parts of
                                clothing (including clothing or PPE bearing
                                D&amp;B&rsquo;s client&rsquo;s logo).
                                &nbsp;However, the Subcontractor will not
                                represent himself as a servant or employee of
                                D&amp;B or of D&amp;B&rsquo;s client at any
                                time, but as an independent subcontractor in
                                business on his own account engaged by D&amp;B
                                for the specific purpose of providing the
                                Services.
                              </li>
                            </ol>
                            <p className="pt-8 pb-4 font-bold text-gray-600">
                              ALCOHOL AND DRUGS&nbsp;
                            </p>
                            <ol start="20" className="list-decimal pl-8">
                              <li>
                                The Subcontractor, or any substitute or hired
                                assistant, must not smoke cigarettes (including
                                e-cigarettes), tobacco or other substances in
                                any enclosed or substantially enclosed premises.
                                Smoking is only permitted outside in designated
                                smoking areas during breaks. When smoking
                                outside, you must dispose of cigarette butts and
                                other litter appropriately in the receptacles
                                provided. This policy also applies to
                                e-cigarettes.&nbsp;
                              </li>
                              <li>
                                The Subcontractor, or any substitute or hired
                                assistant, must comply with drink-driving laws
                                and drug-driving laws at all times.
                              </li>
                              <li>
                                The Subcontractor, or any substitute or hired
                                assistant, must not take any alcohol or drugs,
                                or be under the influence of any alcohol or
                                drugs, whilst on any of D&amp;B&rsquo;s sites or
                                during any period in which the Services are
                                being provided.
                              </li>
                              <li>
                                D&amp;B reserve the right to conduct searches
                                for alcohol or drugs on any D&amp;B premises,
                                including, but not limited to, searches of
                                lockers, filing cabinets and desks, bags,
                                clothing packages and any other item that
                                D&amp;B consider appropriate. This may also
                                include any vehicle located on site. The
                                Subcontractor hereby gives D&amp;B consent (on
                                behalf of the Subcontractor, any substitute or
                                hired assistant) to undertake searches for
                                alcohol or drugs in accordance with the Drug
                                &amp; Alcohol Policy for Self-employed
                                Subcontractors. Any alcohol or drugs found as a
                                result of a search will be confiscated.
                              </li>
                              <li>
                                D&amp;B operates a drug screening programme
                                which will be conducted by D&amp;B&rsquo;s
                                Health &amp; Safety Department in accordance
                                with the Drug &amp; Alcohol Policy for
                                Self-employed Subcontractors. Any Subcontractor,
                                or any substitute or hired assistant, selected
                                for drug testing will be notified at the start
                                of each drug screening programme. The
                                Subcontractor hereby gives D&amp;B consent to
                                test the Subcontractor, or any substitute or
                                hired assistant, for drugs under this clause 2.
                              </li>
                            </ol>
                            <p className="pt-8 pb-4 font-bold text-gray-600">
                              SUBSTITUTES AND HIRED ASSISTANTS&nbsp;
                            </p>
                            <ol start="26" className="list-decimal pl-8">
                              <li>
                                Subject only to clause 27 below, the
                                Subcontractor may, at his absolute discretion,
                                send a substitute or hired assistant to perform
                                the Services.
                              </li>
                              <li>
                                Any substitute or hired assistant sent by the
                                Subcontractor may be rejected by D&amp;B only if
                                in the reasonable opinion of D&amp;B such
                                substitute or hired assistant does not possess
                                the necessary skills or qualifications to carry
                                out the Services.
                              </li>
                              <li>
                                For the purposes of checking the credentials of
                                any substitute or hired assistant 24
                                hours&rsquo; notice of the identity and CITB
                                registration number of the individual must be
                                provided to D&amp;B, or else the substitute or
                                hired assistant must take evidence (e.g. skills
                                and registration cards) to site. &nbsp;All
                                operatives must undergo a site induction and
                                comply with any security restrictions relevant
                                to a particular site.
                              </li>
                              <li>
                                Where a substitute or hired assistant is sent by
                                the Subcontractor there shall be no contractual
                                or financial relationship between D&amp;B and
                                the substitute or hired assistant. The
                                Subcontractor is solely responsible for
                                arranging payments to the substitute or hired
                                assistant.
                              </li>
                            </ol>
                            <p className="pt-8 pb-4 font-bold text-gray-600">
                              DATA PROTECTION&nbsp;
                            </p>
                            <ol start="30" className="list-decimal pl-8">
                              <li>
                                The safe and secure processing of personal data
                                is a legal requirement that we must adhere to.
                                &nbsp;Our obligations with regards to data
                                protection apply for all individuals we engage
                                regardless of how we engage with them. &nbsp;For
                                the avoidance of doubt our data protection
                                policy is non-contractual and does not form part
                                of any contract you agree with us. &nbsp;Any
                                data protection measures that we take do not
                                confer any employment or worker rights on you.
                              </li>
                              <li>
                                If we process personal data or sensitive
                                personal data (also known as &lsquo;special
                                categories of personal data&rsquo;) relating to
                                you we will do so in accordance with our Data
                                Protection Policy which is available from
                                {account && account === "northern"
                                  ? "Caroline Haley"
                                  : "Daisy Sinnott"}
                                .
                              </li>
                            </ol>
                            <p className="pt-8 pb-4 font-bold text-gray-600">
                              TERMINATION OF THIS AGREEMENT&nbsp;
                            </p>
                            <ol start="32" className="list-decimal pl-8">
                              <li>
                                This Agreement can be terminated by either party
                                for any reason and without any notice being
                                given.
                              </li>
                            </ol>
                            <p className="pt-8 pb-4 font-bold text-gray-600">
                              MISCELLANEOUS&nbsp;
                            </p>
                            <ol start="33" className="list-decimal pl-8">
                              <li>
                                The Subcontractor confirms that he has read and
                                understood the terms and conditions herein and
                                has had the opportunity to discuss this
                                agreement with any person or professional
                                adviser he considers necessary before signing.
                              </li>
                              <li>
                                Where these terms have been notified to the
                                Subcontractor and the Subcontractor provides
                                Services without raising any specific objection
                                to these terms, the Subcontractor will be deemed
                                to have accepted these terms.
                              </li>
                              <li>
                                Both parties agree this is intended to be a
                                legally binding contract governing the nature of
                                the contractual relationship between them.
                              </li>
                              <li>
                                Both parties agree that these terms and
                                conditions represent the whole agreement between
                                them. &nbsp;No variations may be made to these
                                terms unless agreed in writing by both parties.
                              </li>
                              <li>
                                Should D&amp;B fail to enforce or apply any of
                                the rights that it has under this Contract for
                                Services, it shall not be construed that D&amp;B
                                approves or agrees to any breach of contract or
                                that it loses its rights to enforce the terms of
                                this Contract for Services in full at any time
                                now or in the future.
                              </li>
                              <li>
                                The headings used in this Contract for Services
                                are for ease of reference only and are not
                                intended to be interpreted as part of the terms
                                agreed between the parties. References to the
                                masculine include the feminine.
                              </li>
                              <li>
                                This contract is governed by the laws of England
                                and Wales.
                              </li>
                            </ol>
                            <Divider />

                            <div className="mb-3 p-2 border-gray-200 bg-gray-100 border rounded ">
                              <Label className="flex-1 p-2 block">
                                <Field
                                  type="checkbox"
                                  name={"confirm_contract"}
                                  className="mr-2"
                                />
                                I agree to this contract.
                              </Label>
                            </div>
                            <InputError>
                              {errors.confirm_contract
                                ? errors.confirm_contract
                                : null}
                            </InputError>
                            <Divider />
                            <Recaptcha
                              sitekey={this.state.captchaKey}
                              render="explicit"
                              verifyCallback={(response) => {
                                setFieldValue("recaptcha", response);
                              }}
                              onloadCallback={() => {
                                console.log("done loading!");
                              }}
                            />
                            <InputError>
                              {errors.recaptcha ? errors.recaptcha : null}
                            </InputError>
                          </>
                        )}
                        <Divider />
                      </>
                    )}

                    <div className="flex flex-row w-full items-between">
                      {this.state.currentStep > 0 ? (
                        <div className="flex-1">
                          <Button
                            appearance="light"
                            disabled={this.state.status === "importing"}
                            onClick={() => {
                              this.setState({
                                currentStep: this.state.currentStep - 1,
                              });
                            }}
                          >
                            Back
                          </Button>
                        </div>
                      ) : (
                        <div className="flex-1"></div>
                      )}

                      {this.state.currentStep >= this.state.steps.length - 1 ? (
                        <Button
                          disabled={this.state.status === "importing"}
                          appearance="dark"
                          type="submit"
                        >
                          Submit
                          <FontAwesomeIcon icon={faCheck} className="ml-4" />
                        </Button>
                      ) : this.state.validatingEmail ? (
                        <Button appearance="dark" type="button" disabled={true}>
                          Validating Email...
                        </Button>
                      ) : (
                        <Button
                          disabled={this.state.status === "importing"}
                          appearance="dark"
                          type="submit"
                        >
                          Continue
                          <FontAwesomeIcon
                            icon={faLongArrowRight}
                            className="ml-4"
                          />
                        </Button>
                      )}
                    </div>

                    <div className="text-right pt-2 border-t border-gray-100 mt-4 flex-1 text-sm text-rose-600">
                      All fields are manditory. If not applicable please put N/A
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </>
    );
  }
}

class Routes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Dashboard} />
        </Switch>
      </Router>
    );
  }
}
export default Routes;
